<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";

import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
/**
 * Galeri Component
 */
export default {
  page: {
    title: "Galeri",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      title: "Galeri",
      items: [
        {
          text: "Beranda",
          href: "/",
        },
        {
          text: "Galeri",
          active: true,
        },
      ],

      id: '99caddf8-7fa1-421a-a7ab-a04e9b56fff5',

      // variable Page
      nama_halaman: null,
      link_youtube_path: null,
      konten: null,
      gambar_halaman_path: null,
      kategori: null,
      meta_content: [],
      meta_content_db: 0,
      link_dev: process.env.VUE_APP_BACKEND_URL,
      optionsKategori: [],
      kategoriSelected: null,
      ShowModal: false,
      picture : null,	

    };
  },
  mounted() {
    if (localStorage.getItem('reloaded')) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem('reloaded');
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem('reloaded', '1');
      location.reload();
    }
    let self = this;
    self.getDataGaleri();

    //galeri kategori
    var config_data_skema_sertifikasi = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/website-galeri-kategori-active",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.access_token,
      },
    };
    axios(config_data_skema_sertifikasi)
      .then(function (response) {
        var response_data = response.data;
        var response_data_fix = response_data.data;
        if (response_data.meta.code == 200) {
          self.optionsKategori = response_data_fix.referensi;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });


  },
  methods: {
    getDataGaleri(idSelected = null) {
      let self = this;
      // get data halaman berdasarkan id
      self.kategoriSelected = idSelected;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
        params: {

        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data;
          if (response_data.meta.code == 200) {
            var data_edit = response_data_fix;
            self.nama_halaman = data_edit.nama_halaman;
            self.gambar_pendukung_path = data_edit.gambar_pendukung;
            self.gambar_pendukung_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_pendukung;
            self.konten = data_edit.konten;
            self.gambar_halaman_path = data_edit.gambar_halaman;
            self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
            self.kategori = data_edit.kategori?.nama_kategori_halaman;

            // META CONTENT
            if (data_edit.meta_content) {
              var json_response_meta_content = JSON.parse(
                data_edit.meta_content
              );
              let clear_data_json_response_meta_content = [];
              $.each(
                json_response_meta_content,
                function (indexInArray, valueOfElement) {
                  clear_data_json_response_meta_content.push({
                    kategori: valueOfElement.kategori,
                    gambar_pendukung: valueOfElement.gambar_pendukung,
                    keterangan: valueOfElement.keterangan,
                    // tanggal_kegiatan: valueOfElement.tanggal_kegiatan,
                    tanggal_kegiatan: new Date(valueOfElement.tanggal_kegiatan).toLocaleDateString('id-ID', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })
                  });
                }
              );
              var kategori_filter = $.map(clear_data_json_response_meta_content, function (item) {
                if (item.kategori.id_galeri_kategori === self.kategoriSelected) return item;
              });

              if (self.kategoriSelected) {
                self.meta_content = kategori_filter;
              } else {
                self.meta_content = clear_data_json_response_meta_content;
              }
              self.meta_content;
              self.meta_content_db = self.meta_content.length;
            }

            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.close();
        });
    },
    ShowModalPicture(item) {
        this.ShowModal = true;
        this.picture = item.gambar_pendukung;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center" style="margin-top:50px;">
              <h1>GALERI</h1>
            </div>
          <div class="col-lg-12">
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                <b-tab active>
                    <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                            <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">ALL</span>
                    </template>
                    <div class="row mt-5">
                      <div class="col-md-3" v-for="(item, index) of meta_content" :key="index">
                        <div class="card p-2" style="width: 100%;" @click="ShowModalPicture(item)">
                          <h5 class="card-title">{{ item.kategori.nama_galeri_kategori }}</h5>
                          <img :src="link_dev + item.gambar_pendukung" class="card-img-top imageContainer" alt="..." >
                          <div class="card-body">
                            <h5 class="card-title">{{ item.tanggal_kegiatan }}</h5>
                            <div v-html="item.keterangan"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                </b-tab>
                <b-tab v-for="(kategori, index) of optionsKategori" :key="index" @click="getDataGaleri(kategori.id_galeri_kategori)">
                    <template v-slot:title >
                        <span class="d-inline-block d-sm-none">
                            <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block" >{{ kategori.nama_galeri_kategori }}</span>
                    </template>
                    <div class="row">
                      <div class="col-md-3" v-for="(item, index) of meta_content" :key="index">
                        <div class="card p-2" style="width: 100%;" @click="ShowModalPicture(item)">
                          <h5 class="card-title">{{ item.kategori.nama_galeri_kategori }}</h5>
                          <img :src="link_dev + item.gambar_pendukung" class="card-img-top imageContainer" alt="..." >
                          <div class="card-body">
                            <h5 class="card-title">{{ item.tanggal_kegiatan }}</h5>
                            <div v-html="item.keterangan"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                </b-tab>
              </b-tabs>
            </div>
            <!-- <div class="row mt-5">                
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-3" v-for="(item, index) of meta_content" :key="index">
                    <div class="card p-2" style="width: 100%;" @click="ShowModalPicture(item)">
                          <img :src="link_dev + item.gambar_pendukung" class="card-img-top" alt="..." style="height: 250px; width: 100%;">
                      <h5 class="card-title">{{ item.kategori.nama_galeri_kategori }}</h5>
                      <img style="height: 250px; width: 100%;" :src="link_dev + item.gambar_pendukung">
                      <div class="card-body">
                        <h5 class="card-title">{{ item.tanggal_kegiatan }}</h5>
                        <div v-html="item.keterangan"></div>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-body">
                    <h2>KATEGORI</h2>
                    <div class="form-group">
                      <div v-for="(item, index) of optionsKategori" :key="index">
                        <input type="radio" v-model="kategoriSelected" @change="getDataGaleri()" name="kategoriSelected"
                          :value="item.id_galeri_kategori"> {{ item.nama_galeri_kategori }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Line with Data Labels chart -->

          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
  <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': ShowModal }">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content"> 
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="ShowModal = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>             
                <div class="modal-body">
                    <img :src="link_dev + picture" class="imagepreview" style="width: 100%;" >
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.imageContainer {
  height: 250px; 
  width: 100%; 
  object-fit:fill
}
  
</style>